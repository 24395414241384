.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit, .delete {
  cursor: pointer;
  margin-top: 3px;
}
.label {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
  width: 60px;
  text-align: left;
}
.cajas{
}
.cajas label{
  display: block;
  width: 100%;
  text-align: left;
}
.labelOnly {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
  text-align: left;
}
.btCerrar{
  background-color: #a4abb1;
  border-color:#a4abb1 ;
}
.edit:hover {
  text-decoration: underline;
}
.fechaCreado{
  font-family: ItalianPlateNoRegular;
  text-align: right;
}
.correlativo{
  font-family: ItalianPlateNoRegular;
  text-align: left;
  margin-bottom: 5px;
}
.sucursal{
  font-family: ItalianPlateNoRegular;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5px;
}
.descripcion{
  text-transform:uppercase;
  font-family: ItalianPlateNoRegular;
  text-align: left;
  margin-top: 20px;
  color: "#545454",
}
.txAdjunto{
  color: "#97B0C1";
  font-family: ItalianPlateNoBold;
  font-size: 16px;
  margin-top: 25px;
  text-transform:uppercase;
}
.contImages{
  flex-direction: row;
  margin-top: 10px;
}
.contImages img{
  cursor: pointer;
}
.linea{
  background-color: #6F7B81;
  height: 1px;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 10px;
}

.actions {
  display: flex;
  align-items: left;
  justify-content: left;
}
.txDetalleTarea {
  font-family: ItalianPlateNoBold;
  color:"#6F7B81";
  font-size: 14px;
}
.divider {
  width: 1px;
  background-color: #C9C9C9;
  height: 22px;
  margin: 0 15px;
}
