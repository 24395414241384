.layout {
  min-height: 100vh;
  background-color: #F2F2F2;
}

.sider {
  background-color: var(--darkGray) !important;
}

.logoWrap {
  margin-bottom: 40px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.logo {
  color: white;
  width: 87px;
  border-radius: 3px;
}

.nav {
  background-color: var(--darkGray);
  border-right: none;
}

.item {
  color: #DEDEDE;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  font-size: 12px;
  border-bottom: 1px solid #878787;
}

.item:nth-child(3) svg {
  width: 30px;
  height: 30px;
}

.item:nth-child(4) svg {
  width: 30px;
  height: 30px;
}
.item:nth-child(5) svg {
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(90deg) brightness(105%) contrast(101%);
}


.item:nth-child(11) svg {
  width: 30px;
  height: 30px;
}
.item:first-child svg {
  width: 30px;
  
}

.item:first-child {
  border-top: 1px solid #878787;
}

.active {
  background-color: var(--green);
  color: white;
}

.active:hover {
  color: white !important;
}

.item span {
  display: block;
  margin-top: 10px;
  text-align: center;
}

.item:hover {
  color: #DEDEDE;
}

.header {
  background-color: transparent;
  height: 81px;
}

.account {
  line-height: 14px;
}

.logoMobile {
  width: 91px;
  color: var(--green);
  display: none;
}

.headerInfo {
  flex: 1;
  display: flex;
  padding: 27px 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #C1C1C1;
  color: #3C3C3C;
}

.title {
  font-family: ItalianPlateNoRegular;
  font-size: 24px;
  line-height: 24px;
}

.wrapDate {
  display: flex;
  align-items: center;
}

.date {
  display: block;
  margin-left: 5px;
  font-weight: 300;
  font-family: ItalianPlateNoRegular;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
}

.accountWrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chevron {
  margin: 0 10px;
}

.iconAccount {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 30px 50px;
  height: calc(100vh - 81px - 60px);
  overflow-y: scroll;
}

.powered {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ItalianPlateNoRegular;
  height: 60px;
}

.powered a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered span {
  margin-right: 10px;
  display: block;
}
.powered img {
  width: 150px;
}

@media only screen and (max-width: 992px) {
  .header {
    background-color: white;
    display: flex;
  }

  .headerInfo {
    border-bottom: none;
    padding: 10px 0;
    margin: 0 -20px;
  }

  .title, .wrapDate, .account, .chevron {
    display: none;
  }

  .logoMobile {
    display: flex;
  }

  .iconAccount {
    height: 30px;
    padding-left: 15px;
    border-left: 1px solid #D2D2D2;
  }

  .content {
    padding: 20px;
  }
}