.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit, .delete {
  cursor: pointer;
  margin-top: 3px;
}

.edit:hover {
  text-decoration: underline;
}
.ant-tabs-ink-bar {
  width: 100px !important; /* Cambia el valor según el ancho deseado */
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 1px;
  background-color: #C9C9C9;
  height: 22px;
  margin: 0 15px;
}
.contMain{
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
}
.espacio{
  height: 10px;
}
.contBuscador{
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding-top: 30px;
}
.spanPeriodo{
  display: block;
  font-family: ItalianPlateNoRegular;
  font-weight: 300;
  font-size: 14px;
  margin-right: 10px;
  margin-left: 20px;
}
.driverAssign,
.accept,
.saveNotes {
  font-weight: 700;
  font-size: 12px;
}

.driverAssign {
  display: block;
  margin-left: 20px;
  width: 100px;
  height: 32px;
  padding: 1px 15px !important;
}