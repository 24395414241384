.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit, .delete {
  cursor: pointer;
  margin-top: 3px;
  height: 1em;
  line-height: 1em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 1; 
   -webkit-box-orient: vertical;
}

.edit:hover {
  text-decoration: underline;
}

.actions {
  display: flex;
  align-items: center;
}

.divider {
  width: 1px;
  background-color: #C9C9C9;
  height: 22px;
  margin: 0 15px;
}
.asunto{
  font-family: ItalianPlateNoRegular;
  margin-top: 20px;
}
.restaurante{
  font-family: ItalianPlateNoRegular;
  font-weight: 600;
}
.fechaCreado{
  font-family: ItalianPlateNoRegular;
  text-align: right;
}
.btCerrar{
  background-color: #a4abb1;
  border-color:#a4abb1 ;
}
.btCerrar:hover{
  background-color: #a4abb1;
  border-color:#a4abb1 ;
}
.btCerrar:focus{
  background-color: #a4abb1;
  border-color:#a4abb1 ;
}
.btDelete{
  background-color: #dc3545;
  border-color:#dc3545 ;
}
.btDelete:hover{
  background-color: #dc3545;
  border-color:#dc3545 ;
}
.btDelete:focus{
  background-color: #dc3545;
  border-color:#dc3545 ;
}