.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  margin-top: 100px;
  justify-content: center;
  flex: 1;
}

.edit, .delete {
  cursor: pointer;
  margin-top: 3px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.map {
  width: 100%;
  height: 100%;
}
.contMapa{
  height: 80vh;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
}
.contRuta{
  display: flex;
  padding: 0px 0px 10px 0px;
}
.ruta{
  border: 1px solid #35ac9c;
  background-color: #fff;
  border-radius: 10px;
  padding: 4px 15px;
  margin-right: 15px;
}
.divider {
  width: 1px;
  background-color: #C9C9C9;
  height: 22px;
  margin: 0 15px;
}
.contOpciones{
  margin-top: 15px;
  display: flex;
}
.contOpciones ul {
  padding-left: 0px;
  display: flex;
}
.contOpciones li {
  display: block;
  width: 200px;
  text-align: center;
  color: #232C4A;
  font-family: ItalianPlateNoRegular;
}
.primaryCardList{
  list-style: none;
  max-height: 600px;
  overflow-y: auto;
}

.primaryCardCard{
  box-shadow:  0px 6px 7px 0px #409AB9D4;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background: #ffffff;
  background-blend-mode: normal;
  box-shadow: 0px 6px 7px rgba(154,185,212,0.25);
  padding: 15px 20px;
  position: relative;
  cursor: pointer;
  margin: 25px 10px;
}

.primaryCardCard .shapeNext{
  position: absolute;
  right: 10px;
}

.primaryCardInfo{
  line-height: 21px;
}
.primaryCardCard svg{
  width: 70px !important;
  height: 70px !important;
  display: block;
  margin: 0px auto;
}
.iconUbicacion{
  padding: 9px;
}
.label{
  width: 90px;
  text-align: left;
}
.sizeTable{
  max-width: 600px;
  margin: 0px auto;
  min-width: 500px;
}