.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit, .delete {
  cursor: pointer;
  margin-top: 3px;
}
.label {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
  width: 60px;
  text-align: left;
}
.labelOnly {
  font-weight: 500;
  color: var(--darkGray);
  font-size: 12px;
  text-align: left;
}
.btCerrar{
  background-color: #a4abb1;
  border-color:#a4abb1 ;
}
.edit:hover {
  text-decoration: underline;
}
.fechaCreado{
  font-family: ItalianPlateNoRegular;
  text-align: right;
}

.actions {
  display: flex;
  align-items: left;
  justify-content: left;
}
.txDetalleTarea {
  font-family: ItalianPlateNoBold;
  color:"#6F7B81";
  font-size: 14px;
}
.divider {
  width: 1px;
  background-color: #C9C9C9;
  height: 22px;
  margin: 0 15px;
}
